body {
  font-family: 'Open Sans', sans-serif !important;
  color: #272c33;
}

a {
  color: #6699b2;
}	


.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  z-index: 999;

  &.show {
    display: flex;
    align-items: center;
  }

  .modal-mask {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .modal-inner {
    position: absolute;
    left: 10%;
    width: 80%;
    //min-height: 500px;

    //top: 20%;
    //height: 60%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: white;

    .modal-header {
      display: flex;
      height: 40px;
      align-items: center;
      flex-direction: row;
      background: #6699b2;
      color: white;
      padding: 5px 20px;
      border-radius: 5px 5px 0 0;

      .title {
	flex: 1;
	font-weight: bold;
      }

      .close {
	color: white;
	font-size: 28px;
	font-weight: bold;
	cursor: pointer;
	&:hover {
	  opacity: 0.5;
	}
      }
    }

    .modal-content {
      flex: 1;
      padding: 20px;
      overflow: auto;
      background-color: #fefefe;
      border: 1px solid #888;
    }
  }

  &.small {
    .modal-inner {
      left: 25%;
      width: 50%;
    }
  }
}

button {
  font-family: 'Open Sans', sans-serif;
}

.button {
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
  &:hover {
    opacity: 0.8;
    transform: scale(1.05);
    &:disabled {
      transform: scale(1);
    }
  }

  &.primary {
    background: #6699b2;
    color: white;
  }

  &.warning {
    background: #FF7452;
    color: white;
  }

  &.green {
    background: #4CB2C1;
    color: white;
  }

  &.negative {
    background: #6699b2;
    background: rgba(64,87,109,.07);
    color: black;
  }

  &:disabled {
    background: grey;
    cursor: not-allowed;
    &:hover {
      opacity: 1; 
    }
  }

  svg {
    margin: 0;
    margin-right: 5px;
  }
}

.link-button {
  background: none;
  outline: none;
  border: none;
  text-decoration: underline;
  padding: 0;
  cursor: pointer;
}

.error {
  color: #E1341E;
}

.clickable {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.common-form {
  display: flex;
  flex-direction: column;
  .field {
    margin: 10px 0;
    text-align: center;

    button {
      width: 100%;
      font-size: 18px;
      &:hover {
	transform: scale(1.01);
      }
    }
  }
}

.common-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  border: solid 1px;
  color: #272c33;
  padding: 8px;
  font-size: 18px;

  input {
    margin-left: 10px;
    font-size: 16px;
    box-sizing: border-box;
    border: none;
    background: none;
    width: 100%;
    outline: none;
  }
}

.common-editable-text {
  display: flex;
  flex-direction: row;

  .input-wrapper {
    display: flex;
    flex-direction: row;
    background: white;
    border: solid 1px;
    color: #272c33;
    padding: 0 5px;
    input {
      box-sizing: border-box;
      border: none;
      background: none;
      width: 100%;
      outline: none;
    }
  }
  svg {
    margin-left: 5px;
    &:hover {
      transform: scale(1.5);
    }
  }
}

.common-checkbox {
  label {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    align-items: center;
    cursor: pointer;
  }
}

table.common-table {
  margin-top: 10px;
  border: solid 1px;
  width: 100%;
  border-spacing: 0;
  font-size: 12px;
  text-align: center;
  thead {
    background: #3f4652;
    color: white;
    tr {
      th {
	padding: 5px;
	&.shrink {
	  width: 1%;
	  white-space: nowrap
	}
      }
    }
  }
  tbody {
    tr {
      border: solid 1px;
      td {
	padding: 5px;
	&.shrink {
	  width: 1%;
	  white-space: nowrap;
	}
      }
    }
  }
}

.common-card-form {
  .common-input {
    padding: 10px 12px;
    svg {
      width: 22px;
      color: #bbb;
    }
  }

  .StripeElement {
    margin: 10px 0;
    padding: 10px 12px;
    //color: #32325d;
    //background-color: white;
    border: 1px solid transparent;
    border-color: #272c33;
    //box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  .stripe-notes {
    text-align: center;
    margin: 10px;
    img {
      height: 25px;
    }
  }

  .submit-button {
    width: 100%;
    height: 35px;
    font-size: 18px;
  }
}
