.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: #89cff0;

  .introduction {
    text-align: center;
    margin-bottom: -70px;
    z-index: 2;
    img {
      width: 153px;
    }
    .title {
      font-size: 41px;
      font-weight: bold;
      margin-top: -130px;
      color: #3f4652;
    }
  }

  .main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    width: 80vw;
    background: #3f4652;
    color: white;
    padding: 20px;

    .form {
      display: flex;
      flex-direction: column;
      width: 80%;

      .title {
	text-transform: uppercase;
	text-align: center;
	font-size: 50px;
      }

      .switch-form {
	display: flex;
	flex-direction: row;
	color: white;
	.right {
	  flex: 1;
	  text-align: right;
	}
	a {
	  cursor: pointer;
	  font-size: 12px;
	  text-decoration: underline;
	}
	button {
	  color: white;
	}
      }

      .footnote {
	font-size: 12px;
	margin-top: 8px;
      }
    }
  }

  .terms {
    a {
      margin: 0 5px;
      font-size: 12px;
      color: #3f4652;
    }
  }
}
