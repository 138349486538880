.dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .main {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #3f4652;
    padding: 20px;
    overflow: auto;

    .warning {
      text-align: center;
      background: #ff8a80; 
      color: white;
      padding: 15px 5px;
      margin-bottom: 20px;
    }

    .toolbar {
      display: flex;
      flex-direction: row;
      .left {
	font-weight: bold;
	color: white;
	flex: 1;
      }
    }

    .create-project-section {
      position: relative;
      margin-top: 10px;
      background: white;
      padding: 10px;

      .close {
	position: absolute;
	right: 20px;
	top: 0px;
	font-size: 40px;
      }

      .instruction {
	padding: 10px;
	font-weight: bold;
      }

      .items {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.item {
	  display: flex;
	  width: calc(25% - 25px);
	  @media only screen and (max-width: 1200px) {
	    width: calc(50% - 25px);
	  }

	  height: 200px;
	  flex-direction: row;
	  position: relative;
	  display: flex;
	  margin: 10px;
	  align-items: center;
	  justify-content: center;
	  border: solid 2px;

	  .thumbnail {
	    width: 200px;
	    height: 200px;
	    img {
	      width: 100%;
	      height: 100%;
	      object-fit: cover;
	    }
	  }
	  .details {
	    padding: 10px;
	    flex: 1;
	    display: flex;
	    height: 100%;
	    flex-direction: column;
	    .title {
	      margin-top: 10px;
	      font-weight: bold;
	      font-size: 18px;
	      text-align: center;
	      white-space: nowrap;
	    }
	    .description {
	      flex: 1;
	      font-size: 14px;
	      ul {
		padding-left: 20px;
	      }
	    }
	    .buttons {
	      margin-bottom: 10px;
	      button {
		width: 100%;
	      }
	    }
	  }
	}
      }
    }

    table {
      background: white;
      margin-top: 10px;
      border: solid 1px;
      width: 100%;
      border-spacing: 0;
      font-size: 12px;
      thead {
	background: #3f4652;
	color: white;
	tr {
	  th {
	    padding: 10px;
	    text-align: left;
	  }
	}
      }
      tbody {
	tr {
	  border: solid 1px;
	  cursor: pointer;
	  td {
	    padding: 10px;

	    &:last-child {
	      text-align: right;
	    }

	    .copy-icon {
	      cursor: pointer;
	      &:hover {
		transform: scale(1.5);
	      }
	    }
	    .trash-icon {
	      cursor: pointer;
	      &:hover {
		transform: scale(1.5);
	      }
	    }
	    .chart-icon {
	      margin-left: 5px;
	      cursor: pointer;
	      &:hover {
		transform: scale(1.5);
	      }
	    }
	  }
	  &:hover {
	    background: #eee;
	  }
	}
      }
    }

    /*
    table {
      background: white;
      width: 100%;
      border: 0;
      thead {
      border: 0;
	text-align: left;
	tr {
	  background: grey;
	  th {
	    padding: 5px;
	  }
	}
      }
      tbody {
	tr {
	  td {
	    padding: 5px;
	  }
	}
      }
    }
    */

    .projects {
      display: flex;
      flex-direction: row;
      padding: 20px;
      flex-wrap: wrap;
      align-self: flex-start;

      .project {
	margin: 10px;
	display: flex;
	height: 200px;
	width: 200px;
	border: solid 5px #272c33;
	border-radius: 5px;
	background: #ebeced;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	font-size: 25px;
	&:hover {
	  opacity: 0.8;
	}
      }
      .add-project {
	svg {
	  margin-right: 5px;
	  font-size: 14px;
	  vertical-align: middle;
	}
      }
    }
  }
}

.chart-modal {
  .chart {
    width: 100%;
  }
}

.usage-modal {
  .tips {
    margin-top: 20px;
    font-size: 12px;
  }
}
