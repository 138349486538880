.asset-modal {
  .modal-inner {
    height: 60%;
  }
  .toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    .left {
      flex: 1;
      .search-wrapper {
	border: solid 1px;
	width: 300px;
	padding: 2px;

	svg {
	  margin: 0 10px;
	}

	input {
	  border: none;
	  outline: none;
	  font-size: 12px;
	}
      }
    }
    .right {
      text-align: right;
    }
  }
  .assets-list {
    margin: 0 -10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .item {
      position: relative;
      align-items: center;
      margin: 10px;
      width: 100px;
      display: flex;
      flex-direction: column;
      font-size: 10px;

      .control {
	background: #6699b2;
	position: absolute;
	align-items: center;
	//display: flex;
	flex-direction: row;
	color: white;
	width: calc(100% - 10px);
	height: 20%;
	padding: 0 5px;
	left: 0;
	top: 0;
	z-index: 2;
	.left {
	  flex: 1;
	}
	display: none;
	.right {
	  text-align: right;
	  svg {
	    //color: #E1341E;
	    font-size: 13px;
	  }
	}
      }
      &:hover {
	.control {
	  display: flex;
	}
      }

      .thumbnail {
	background: #eee;
	object-fit: contain;
	object-position: center;
	width: 100%;
	height: 100px;
	cursor: pointer;
	border: solid 1px;
	border-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
	  //border-color: #272c33;
	  opacity: 0.8;
	}

	svg {
	  width: 50%;
	  height: 50%;
	}
      }

      .name {
	margin-top: 10px;
	text-align: center;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	.common-editable-text {
	  justify-content: center;
	  .current {
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	  }
	}
      }
    }
  }

  .upload-form {
    height: 100%;
    .top-bar {
      text-align: left;
      margin-bottom: 10px;
    }

    .main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: calc(100% - 30px);
      .dropzone {
	display: flex;
	flex-direction: column;
	cursor: pointer;
	flex: 1;
	width: 100%;
	align-items: center;
	justify-content: center;
	border: solid 1px;

	.title {
	  font-size: 20px;
	  font-weight: bold;
	  margin-bottom: 40px;
	}
	.format {
	  .sub-title {
	    text-decoration: underline;
	    margin-bottom: 5px;
	  }
	  text-align: center;
	}
      }

      .preview {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: calc(100% - 100px);
	margin: 30px;

	.field {
	  width: 100%;
	  margin: 10px 0;
	  text-align: center;
	  .preview-image {
	    width: 100%;
	    height: 100%;
	    object-fit: contain;
	  }
	  .preview-model {
	    position: relative;
	    width: 100%;
	    height: 100%;
	    canvas {
	      width: 100%;
	      height: 100%;
	      object-fit: contain;
	    }

	    .guide {
	      position: absolute;
	      display: flex;
	      top: 5px;
	      left: calc(50% - 140px);
	      width: 10px;
	      svg {
		margin: 0 1px;
	      }
	    }
	  }
	  input {
	    font-size: 16px;
	    padding: 8px;
	    box-sizing: border-box;
	    border: solid 1px #272c33;
	    color: #272c33;
	    width: 100%;
	    outline: none;
	  }
	  button {
	    width: 100%;
	    font-size: 20px;
	  }

	  &.image-field {
	    flex: 1;
	    height: calc(100% - 50px);

	    video {
	      height: 100%;
	    }
	  }
	  &.buttons-field {
	    display: flex;
	    flex-direction: row;
	    button:first-child {
	      margin-right: 10px;
	    }
	    button:last-child {
	      margin-left: 10px;
	    }
	    button {
	      height: 35px;
	    }
	  }
	}
      }
    }
  }

  .preview-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 100px);
    margin: 30px;

    .field {
      width: 100%;
      margin: 10px 0;
      text-align: center;
      .preview-image {
	width: 100%;
	height: 100%;
	object-fit: contain;
      }
      input {
	font-size: 16px;
	padding: 8px;
	box-sizing: border-box;
	border: solid 1px #272c33;
	color: #272c33;
	width: 100%;
	outline: none;
      }
      button {
	width: 100%;
	font-size: 20px;
      }

      &.image-field {
	flex: 1;
	height: calc(100% - 50px);
      }
      &.buttons-field {
	display: flex;
	flex-direction: row;
	button:first-child {
	  margin-right: 10px;
	}
	button:last-child {
	  margin-left: 10px;
	}
      }
    }
  }

  .processing {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;

    .inner {
      padding: 20px 60px;
      background: #272c33;
      color: white;
      svg {
	margin-right: 10px;
      }
    }
  }
}
