.upgrade-modal {
  .modal-inner {
    .modal-header {
      text-align: center;
    }
  }
  .main {
    text-align: center;
    padding: 10px;
    .plan {
      text-align: left;
      .title {
	font-weight: bold;
      }

      ul {
	padding-left: 20px;
	text-align: left;
      }
    }

    .form {
      border: solid 2px;
      padding: 20px;
      .guide {
	margin-bottom: 5px;
      }
      .current-card {
	margin: 10px;
	font-size: 14px;
      }

      .common-input {
	padding: 10px 12px;
	svg {
	  width: 22px;
	  color: #bbb;
	}
      }

      .StripeElement {
	margin: 10px 0;
	padding: 10px 12px;
	//color: #32325d;
	//background-color: white;
	border: 1px solid transparent;
	border-color: #272c33;
	//box-shadow: 0 1px 3px 0 #e6ebf1;
	-webkit-transition: box-shadow 150ms ease;
	transition: box-shadow 150ms ease;
      }
      .StripeElement--focus {
	box-shadow: 0 1px 3px 0 #cfd7df;
      }
      .StripeElement--invalid {
	border-color: #fa755a;
      }
      .StripeElement--webkit-autofill {
	background-color: #fefde5 !important;
      }

      .stripe-notes {
	margin-top: 10px;
	img {
	  height: 25px;
	}
      }

      .pay-button {
	width: 100%;
	height: 35px;
	font-size: 18px;
      }
    }

    .footnote {
      font-size: 11px;
      color: #555;
      text-align: left;
      p {
	margin: 5px 0;
      }
    }
  }
}

.open-app-modal {
  .modal-content {
    .main {
      display: flex;
      flex-direction: row;
      .left {
	width: 50%;
	text-align: center;
	border-right: solid 1px;
	.title {
	  font-size: 14px;
	  margin-bottom: 10px;
	}
	.url {
	  font-weight: bold;
	  word-wrap: break-word;
	}
	.qrcode {
	  max-width: 300px;
	  max-height: 300px;
	}
      }
      .right {
	width: 50%;
	text-align: center;
	border-left: solid 1px;
	.title {
	  font-size: 14px;
	}
	.images {
	  svg {
	    flex: 1;
	    cursor: pointer;
	  }
	  display: flex;
	  flex-direction: row;
	  align-items: center;
	  .target-image-wrapper {
	    width: 300px;
	    height: 300px;
	    line-height: 300px;
	    img {
	      max-width: 100%;
	      max-height: 100%;
	      vertical-align: middle;
	    }
	  }
	}
      }
    }
    .instruction {
      margin-top: 20px;
      text-align: center;
      font-size: 12px;
      .title {
	text-decoration: underline;
	font-weight: bold;
      }
    }
  }
}
.publish-modal {
  .modal-content {
    min-height: 300px;

    .button {
      width: 100px;
      margin: 0 5px
    }

    .notice {
      display: flex;
      flex-direction: row;
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 30px;
      border: solid 1px;
      padding: 5px;
      .button {
      }
      .left {
	flex: 1;
      }
      .right {
	text-align: right;
      }
    }

    .live-details-button {
      margin-left: 20px;
    }

    .toolbar {
      margin-left: -5px;
      .build-status {
	svg {
	  margin-right: 5px;
	}
	font-size: 13px;
      }
    }

    table {
      margin-top: 10px;
      border: solid 1px;
      width: 100%;
      border-spacing: 0;
      font-size: 12px;
      text-align: center;
      thead {
	background: #3f4652;
	color: white;
	tr {
	  th {
	    padding: 5px;
	    &.shrink {
	      width: 1%;
	      white-space: nowrap
	    }
	  }
	}
      }
      tbody {
	tr {
	  border: solid 1px;
	  td {
	    padding: 5px;
	    &.shrink {
	      width: 1%;
	      white-space: nowrap;
	    }
	  }
	}
      }
    }
  }
}

.setup-release-modal {
  .modal-content {
    .title {
      margin-bottom: 20px;
    }
    .notes {
      font-size: 14px;
    }
    .key-field {
      display: flex;
      flex-direction: row;
      font-size: 16px;
      text-align: left;
      align-items: center;

      input {
	flex: 1;
	font-size: 20px;
	margin-left: 3px;
	border: solid 1px;
	color: #272c33;
	box-sizing: border-box;
	outline: none;
      }
    }
  }
}
