.admin {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .main {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #3f4652;
    padding: 20px;
    overflow: auto;

    .update-member {
      color: white;
    }

    .toolbar {
      display: flex;
      flex-direction: row;
      .left {
	font-weight: bold;
	color: white;
	flex: 1;
	display: flex;
	align-items: center;
	textarea {
	  margin-right: 10px;
	}
	input {
	  width: 50px;
	  margin-right: 10px;
	}
      }
    }

    table {
      background: white;
      margin-top: 10px;
      border: solid 1px;
      width: 100%;
      border-spacing: 0;
      font-size: 12px;
      thead {
	background: #3f4652;
	color: white;
	tr {
	  th {
	    padding: 10px;
	    text-align: left;
	  }
	}
      }
      tbody {
	tr {
	  border: solid 1px;
	  cursor: pointer;
	  td {
	    padding: 10px;

	    &:last-child {
	      text-align: right;
	    }

	    .trash-icon {
	      cursor: pointer;
	      &:hover {
		transform: scale(1.5);
	      }
	    }
	    .chart-icon {
	      margin-left: 5px;
	      cursor: pointer;
	      &:hover {
		transform: scale(1.5);
	      }
	    }
	  }
	  &:hover {
	    background: #eee;
	  }
	}
      }
    }
  }
}
