.common-menu {
  height: 40px;
  background: #89cff0;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: right;

  .left {
    flex: 1;
    display: flex;
    text-align: left;
    margin: 10px;
    font-weight: bold;
    .logo {
      height: 20px;
      margin-right: 5px;
    }
    .icon {
      margin-right: 5px;
    }

    .breadcrumb {
      display: flex;
      flex-direction: row;
      font-weight: normal;

      > div {
	margin: 5px;
	&:first-child {
	  margin-left: 0;
	}
	&.current {
	  font-weight: bold;
	}
	&.link {
	  cursor: pointer;
	  text-decoration: underline;
	}
      }

      .clickable {
	&:hover {
	  transform: scale(1.5);
	}
      }
    }
  }
  .right {
    text-align: right;
    margin: 5px;
    .button {
      min-width: 100px;
      margin: 0 5px;
    }

    .help {
      display: inline-block;
      color: #272c33;
      margin-right: 10px;
      svg {
	margin-right: 5px;
      }
      a {
	color: inherit;
	text-decoration: none;
      }
    }
  }
  .dropdown {
    .handle {
      cursor: pointer;
      svg {
	margin-left: 5px;
      }
    }
    .content {
      background: white;
      position: fixed;
      right: 0;
      display: none;
      border: solid 1px #272c33;
      &.show {
	display: block;
      }
      .item {
	padding: 5px 20px;
	text-align: left;
	cursor: pointer;
	&:hover {
	  background: #eee;
	}
	svg {
	  margin-right: 15px;
	  width: 20px;
	}
      }
    }
  }
}

.common-colorpicker {
  .color {
    width: 36px;
    height: 14px;
    border-radius: 2px;
    //background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
  }
  .swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
  }
  .popover {
    position: absolute;
    right: 0;
    z-index: 2;
  }
  .cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}
