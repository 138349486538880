.account {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .main {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #3f4652;
    padding: 20px;
    overflow: auto;

    .tabs {
      display: flex;
      flex-direction: row;
      .item {
	color: white;
	margin-right: 20px;
	cursor: pointer;
	&.selected {
	  text-decoration: underline;
	}
      }
    }

    .content {
      //background: white;
      margin-top: 20px;
      //padding: 20px;

      .storage {
	background: white;
	padding: 20px;
      }

      .payment {
	background: white;
	padding: 20px;
	font-size: 14px;
	.current-card {
	  .title {
	    font-size: 16px;
	    font-weight: bold;
	    margin-bottom: 5px;
	  }
	  margin-bottom: 20px;
	}
	.card-form {
	  margin-top: 20px;
	  padding: 10px;
	  border: solid 1px;
	  width: 400px;
	}
      }

      table {
	background: white;
	margin-top: 10px;
	border: solid 1px;
	width: 100%;
	border-spacing: 0;
	font-size: 12px;
	thead {
	  background: #3f4652;
	  color: white;
	  tr {
	    th {
	      padding: 10px;
	      text-align: left;
	    }
	  }
	}
	tbody {
	  tr {
	    border: solid 1px;
	    td {
	      padding: 10px;

	      button {
		width: 200px;
	      }
	    }
	  }
	}
      }
    }
  }

}
