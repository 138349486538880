.editor {
  display: flex;
  flex-direction: column;
 
  .main {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 40px);

    .loading-status {
      position: absolute;
      top: 50%;
      text-align: center;
      width: 100%;
      font-size: 20px;
      svg {
	margin-right: 10px;
      }
    }
    
    .scene-panel {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 230px;
      background: #3f4652;
      color: white;
      overflow-y: auto;

      .kickstart-tip {
	padding: 50px;
	font-size: 20px;
	font-weight: bold;
	color: #ff6e00 !important;
      }

      .target {
	border: solid 1px black;
	border-bottom: solid 8px black;
	font-size: 12px;
	margin-bottom: 5px;
	.image-target.item {
	  background: #ccc;
	  color: black;
	  padding-left: 2px;
	  &:hover,&.expanded {
	    background: #272c33;
	    color: white;
	  }
	  &.add-image-target {
	    padding-left: 21px;
	  }
	}

	.items {
	  max-height: 0;
	  overflow: auto;
	  &.show {
	    max-height: 100vh;
	  }
	}

	.item {
	  background: white;
	  color: black;
	  display: flex;
	  align-items: center;
	  flex-direction: row;
	  padding: 10px 12px 10px 21px;
	  cursor: pointer;
	  .icon {
	    width: 12px;
	    margin-right: 10px;
	  }
	  .name {
	    flex: 1;
	  }
	  &.selected,&:hover {
	    background: #ddd;
	  }
	  .right {
	    svg {
	      margin: 0 4px;
	      outline: none;
	      &:hover {
		opacity: 0.8;
		transform: scale(1.5);
	      }
	    }
	  }
	}
      }
      .project-size {
	font-size: 16px;
	font-weight: bold;
	text-align: center;
      }
    }

    .properties-panel {
      font-size: 12px;
      width: 200px;
      padding: 10px;
      background: #3f4652;
      color: white;
      overflow: auto;

      .section {
	border-top: dashed 1px;
	margin: 10px 0;
	padding: 10px 0;
      }

      .title {
	text-align: center;
	font-weight: bold;
	margin-bottom: 15px;
      }

      .target-image {
	width: 100%;
      }
      .target-image-change-button {
	margin-top: 5px;
	width: 100%;
      }

      .sub-title {
	padding: 2px;
      }

      .row {
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
	align-items: center;

	.label-col {
	  width: 100px;
	}
	.value-col {
	  flex: 1;
	  input[type=number]{
	    width: 50px;
	  } 
	  textarea {
	    width: 130px;
	  }
	}

	.col {
	  flex: 1;
	  width: 20px;
	  margin: 0 2px;
	}

	input[type=checkbox] {
	  transform: scale(1.5);
	  margin-right: 10px;
	}

	.script {
	  font-size: 16px;
	  svg {
	    margin-right: 5px;
	    margin-left: 2px;
	  }
	}
      }
    }

    .viewport {
      position: relative;
      flex: 1;
      height: 100%;
      overflow: hidden;

      canvas {
	width: 100%;
      }

      .history-toolbar {
	display: none;
	flex-direction: column;
	align-items: flex-end;
	right: 10px;
	&.show {
	  display: flex;
	}
	.history {
	  color: white;
	  width: 200px;
	  height: 100px;
	  font-size: 11px;
	  background: #3f4652;
	  overflow-y: auto;
	  display: none;
	  &.show {
	    display: block;
	  }

	  .item {
	    padding: 4px;
	    display: flex;
	    flex-direction: row;
	    align-items: center;
	    cursor: pointer;
	    svg {
	      margin-right: 4px;
	    }
	    &.selected,&:hover {
	      background: #272c33;
	    }
	  }
	}
      }

      .script-toolbar {
	position: absolute;
	bottom: 10px;
	left: 10px;
	z-index: 3;
      }

      .simulate-toolbar {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 3;
	svg {
	  width: 50px;
	  height: 50px;
	  cursor: pointer;
	  &:hover {
	    transform: scale(1.05);
	  }
	}
      }

      .transform-toolbar {
	display: none;
	left: 10px;
	&.show {
	  display: flex;
	}
      }

      .transform-toolbar,.history-toolbar {
	position: absolute;
	top: 10px;
	z-index: 3;

	.buttons {
	  display: flex;
	  flex-direction: row;

	  button {
	    cursor: pointer;
	    border: solid 1px #3f4652;
	    outline: none;
	    img {
	      width: 30px;
	    }
	    &:hover,&.selected {
	      background: #3f4652;
	      color: white;
	    }
	  }
	}
      }

      .view-helper {
	position: absolute;
	right: 0;
	bottom: 0;
	height: 128px;
	width: 128px;
	z-index: 3;
      }

      .performance {
	position: absolute;
	left: 5px;
	bottom: 5px;
	font-size: 11px;
	z-index: 3;
      }
    }
  }

  .good {
    color: green;
  }
  .warning {
    color: orange;
  }
  .bad,.critical {
    color: red;
  }
}

.project-size-modal {
  .status {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .recommend {
    font-size: 13px;
    font-weight: bold;
  }
  .good {
    color: green;
  }
  .warning {
    color: orange;
  }
  .bad {
    color: red;
  }
}

.add-content-modal {
  .type-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 50px 0;

    .type {
      width: 200px;
      height: 200px;
      margin: 20px;
      padding: 10px;
      border: solid 1px;
      cursor: pointer;
      text-align: center;

      .icon {
	width: 60%;
	height: 60%;
      }

      .title {
	margin-top: 30px;
      }
      .sub-title {
	font-size: 12px;
      }
    }
  }
}

.add-embed-modal {
  .title {
    margin-bottom: 10px;
  }
  .embed-preview {
    margin: 10px 0;
  }
}

.player-modal {
  .modal-inner {
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    .modal-content {
      padding: 0;
    }
  }

  .outer-container {
    margin: auto;
    position: relative;
    height: 90%;
    top: 5%;
    aspect-ratio: 1.377;

    img.ipad-border {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      pointer-events: none;
      user-select: none;
    }
    .container {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
}

.script-modal {
  .buttons {
    text-align: center;
    button {
      margin: 10px;
      width: 200px;
    }
  }
  .CodeMirror {
    height:  70vh;
  }
}
